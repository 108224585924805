<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>
                Get Invoice
            </h3></v-card-title
        >
        <v-divider class="ml-4 mr-5" />
        <v-card-text class="pt-4">
            <v-form v-model="valid">
                <v-row no-gutters>
                    <v-col cols="12" class="pt-4">
                        <v-text-field
                            v-model="number"
                            label="Number *"
                            prepend-icon="mdi-receipt-text-outline"
                            :rules="[rules.required]"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
                @click="findInvoice"
                color="primary"
                :loading="loading"
                rounded
                :disabled="!valid"
            >
                FIND
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'FindInvoice',
    data: () => ({
        loading: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required.',
        },
        number: '',
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        async findInvoice() {
            try {
                this.loading = true
                const invoice = await API.getInvoice(this.number)
                this.$emit('openInvoice', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
